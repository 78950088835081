exports.components = {
  "component---src-pages-partners-404-es-tsx": () => import("./../../../src/pages/partners/404.es.tsx" /* webpackChunkName: "component---src-pages-partners-404-es-tsx" */),
  "component---src-pages-partners-404-tsx": () => import("./../../../src/pages/partners/404.tsx" /* webpackChunkName: "component---src-pages-partners-404-tsx" */),
  "component---src-pages-partners-calculator-es-tsx": () => import("./../../../src/pages/partners/calculator.es.tsx" /* webpackChunkName: "component---src-pages-partners-calculator-es-tsx" */),
  "component---src-pages-partners-calculator-tsx": () => import("./../../../src/pages/partners/calculator.tsx" /* webpackChunkName: "component---src-pages-partners-calculator-tsx" */),
  "component---src-pages-partners-contact-us-es-tsx": () => import("./../../../src/pages/partners/contact-us.es.tsx" /* webpackChunkName: "component---src-pages-partners-contact-us-es-tsx" */),
  "component---src-pages-partners-contact-us-tsx": () => import("./../../../src/pages/partners/contact-us.tsx" /* webpackChunkName: "component---src-pages-partners-contact-us-tsx" */),
  "component---src-pages-partners-index-es-tsx": () => import("./../../../src/pages/partners/index.es.tsx" /* webpackChunkName: "component---src-pages-partners-index-es-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-partners-lolo-brand-es-tsx": () => import("./../../../src/pages/partners/lolo-brand.es.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-brand-es-tsx" */),
  "component---src-pages-partners-lolo-brand-tsx": () => import("./../../../src/pages/partners/lolo-brand.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-brand-tsx" */),
  "component---src-pages-partners-lolo-franchise-es-tsx": () => import("./../../../src/pages/partners/lolo-franchise.es.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-franchise-es-tsx" */),
  "component---src-pages-partners-lolo-franchise-tsx": () => import("./../../../src/pages/partners/lolo-franchise.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-franchise-tsx" */),
  "component---src-pages-partners-lolo-success-es-tsx": () => import("./../../../src/pages/partners/lolo-success.es.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-success-es-tsx" */),
  "component---src-pages-partners-lolo-success-tsx": () => import("./../../../src/pages/partners/lolo-success.tsx" /* webpackChunkName: "component---src-pages-partners-lolo-success-tsx" */),
  "component---src-pages-partners-sign-up-finish-es-tsx": () => import("./../../../src/pages/partners/sign-up-finish.es.tsx" /* webpackChunkName: "component---src-pages-partners-sign-up-finish-es-tsx" */),
  "component---src-pages-partners-sign-up-finish-tsx": () => import("./../../../src/pages/partners/sign-up-finish.tsx" /* webpackChunkName: "component---src-pages-partners-sign-up-finish-tsx" */),
  "component---src-pages-privacy-policy-es-tsx": () => import("./../../../src/pages/privacy-policy.es.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-es-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-es-tsx": () => import("./../../../src/pages/terms-and-conditions.es.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-es-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-and-conditions-usa-es-tsx": () => import("./../../../src/pages/terms-and-conditions-usa.es.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-usa-es-tsx" */),
  "component---src-pages-terms-and-conditions-usa-tsx": () => import("./../../../src/pages/terms-and-conditions-usa.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-usa-tsx" */),
  "component---src-pages-user-agreement-tsx": () => import("./../../../src/pages/user-agreement.tsx" /* webpackChunkName: "component---src-pages-user-agreement-tsx" */)
}

